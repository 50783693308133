import { Empty, Input } from "antd";
import React, { useState, useEffect, useRef } from "react";
import data from "./assets/data.json";
import Logo from "./assets/protocol.png";

const Home = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightFound, setHighlightFound] = useState(false);
  const cardContainerRef = useRef(null);

  const handleChange = (e) => {
    const searchItem = e.target.value.trim();
    setSearchTerm(searchItem);
    setHighlightFound(false);
  };

  useEffect(() => {
    const scrollToHighlightedElement = () => {
      if (highlightFound) {
        const highlightedElement = cardContainerRef.current.querySelector('.highlight');
        if (highlightedElement) {
          highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    };

    // Delay the execution of scrollToHighlightedElement
    const timeoutId = setTimeout(scrollToHighlightedElement, 0);

    // Clean up the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, [highlightFound]);

  const highlightText = (text, searchTerm) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, "gi");
    const matchExists = text.search(regex) >= 0;
    if (matchExists && !highlightFound) {
      setHighlightFound(true);
    }
    return text.split(regex).map((chunk, index) =>
      regex.test(chunk) ? (
        <span key={index} className="highlight">
          {chunk}
        </span>
      ) : (
        <span key={index}>{chunk}</span>
      )
    );
  };

  return (
    <div className="Home-container bg-black min-h-screen relative w-screen">
      <div ref={cardContainerRef} className="card-container break-all w-full mb-20">
        {data && data.length > 0 ? (
          data.map((item, index) => (
            <span
            className="mr-2"
            key={index}>{highlightText(item, searchTerm)}</span>
          ))
        ) : (
          <div className="empty-state">
            <Empty description="No addresses found" />
          </div>
        )}
      </div>
      <div className="flex gap-4 p-4 items-center fixed w-full bottom-0 left-0 h-20 bg-white">
        <img src={Logo} alt="logo" className="h-10" />
        <Input
          className="search-input"
          placeholder="Enter wallet address"
          value={searchTerm}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Home;